import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {LinkService} from '../i18n/link.service'
import {EMPTY, Observable} from 'rxjs'
import {PUBLIC_ROUTES} from '../../shared/routing/public.routes'
import {unlocalize} from '../utils/url'
import {TranslocoService} from '@ngneat/transloco'
import {Language} from '../i18n/language'
import {ErrorMonitor} from '../error/error-monitoring.types'
import {Logger} from '../logging/logger.types'

@Injectable({
  providedIn: 'root',
})
export class ResolverUtilService {
  constructor(
    private router: Router,
    private linkService: LinkService,
    private localeService: TranslocoService,
    private errorMonitor: ErrorMonitor,
    private readonly logger: Logger,
  ) {}

  /**
   * Handles an error and redirect to the not found
   */
  handleError(error: Error, notFoundPath: string): Observable<never> {
    this.logger.warn('caught and handled error', {error, notFoundPath})
    this.errorMonitor.addBreadcrumb({level: 'info', message: `Path: ${notFoundPath}`}).then(() => {
      this.errorMonitor.captureException(error)
    })
    this.router.navigateByUrl(this.linkService.localizedPathToNotFound(notFoundPath))
    return EMPTY
  }

  /**
   * Handles an unknown error and redirect to the global unknown page error
   */
  handleUnknownError(error: Error): Observable<never> {
    this.logger.error('caught and handled unknown error', error)
    this.router.navigateByUrl(this.linkService.localizedPathToUnknownError())
    this.errorMonitor.captureException(error)
    return EMPTY
  }

  redirectToNotFound(notFoundPath: string): Observable<never> {
    this.router.navigateByUrl(this.linkService.localizedPathToNotFound(notFoundPath))
    this.errorMonitor.captureException(new Error(`Page not found: ${notFoundPath}`))
    return EMPTY
  }

  redirectToUnsupportedLanguagePage(currentUrl: string, supportedLanguages?: Language[]): Observable<never> {
    const locale = this.localeService.getActiveLang()
    const unlocalizedPath = unlocalize(currentUrl, locale)
    this.router.navigate([locale, PUBLIC_ROUTES.UNSUPPORTED_LANGUAGE_PAGE.routingPath], {
      queryParams: {
        path: unlocalizedPath,
        langs: supportedLanguages,
      },
    })
    return EMPTY
  }

  redirectToSignIn(opts: {signOut: boolean; redirectTo?: string}): Observable<never> {
    this.linkService.navigateToSignIn(opts)
    return EMPTY
  }
}
